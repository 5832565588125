import api from './index';

class IncomeService {
    async find(startDate, endDate) {
        return api.get(`/incomes`, {
            params: {
                startDate: startDate,
                endDate: endDate,
            },
        });
    }

    async create(data) {
        return api.post(`/incomes`, data);
    }

    async update(data) {
        return api.put(`/incomes`, data);
    }

    async delete(id) {
        return api.delete(`/incomes/${id}`);
    }

    async deleteMany(selectedCategories) {
        return api.post(`/incomes/deleteMany`, selectedCategories);
    }
}

export default new IncomeService();